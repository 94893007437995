export interface NoiseGateOptions {
    threshold?: number;
    knee?: number;
    ratio?: number;
    reduction?: number;
    attack?: number;
    release?: number;
    Q?: number;
    frequency?: number;
    gain?: number;
    type?: BiquadFilterType;
}

const FILTER_PARAMS = ['type', 'frequency', 'gain', 'detune', 'Q'];
const COMPRESSOR_PARAMS = ['threshold', 'knee', 'ratio', 'attack', 'release'];
const DEFAULT_OPTIONS = {
  threshold: -50,
  knee: 40,
  ratio: 12,
  reduction: -20,
  attack: 0,
  release: 0.25,
  Q: 8.30,
  frequency: 3550,
  gain: 3.0,
  type: 'bandpass',
};

export class NoiseGateNode {
  private compressor: DynamicsCompressorNode;
  private filter: BiquadFilterNode;

  constructor(audioCtx: AudioContext, options: NoiseGateOptions = {}) {
    options = Object.assign({}, DEFAULT_OPTIONS, options);

    let compressorPramas = this.selectParams(options, COMPRESSOR_PARAMS);
    let filterPramas = this.selectParams(options, FILTER_PARAMS);

    this.compressor = new DynamicsCompressorNode(audioCtx, compressorPramas);
    this.filter = new BiquadFilterNode(audioCtx, filterPramas);

    // this.compressor.connect(this.filter);

    // return this.filter;
  }

  public getNoiseGate() {
      return this.compressor;
  }

  selectParams(object: any, filterArr: any) {
    return Object.keys(object).reduce(function (opt: any, p) {
      if (filterArr.includes(p)) {
        opt[p] = object[p];
      }
      return opt;
    }, {});
  }

  setParams(node: any, audioParams: any) {
    for (let param in audioParams) {
      let value = audioParams[param];
      if (node[param] instanceof AudioParam) {
        node[param].value = value;
      } else {
        node[param] = value;
      }
    }
  }
}