class Base64 {
    private keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    private _utf8_encode(input: string) {
        input = input.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < input.length; n++) {
          var c = input.charCodeAt(n);
          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if ((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        }
        return utftext;
      }
    
      private _utf8_decode(utftext: string) {
        var string = "";
        var i = 0;
        var c = 0,
          c1 = 0,
          c2 = 0,
          c3 = 0;
        while (i < utftext.length) {
          c = utftext.charCodeAt(i);
          if (c < 128) {
            string += String.fromCharCode(c);
            i++;
          } else if ((c > 191) && (c < 224)) {
            c2 = utftext.charCodeAt(i + 1);
            string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
            i += 2;
          } else {
            c2 = utftext.charCodeAt(i + 1);
            c3 = utftext.charCodeAt(i + 2);
            string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
            i += 3;
          }
        }
        return string;
      }
    
    encode(input: string) {
      var output = "";
      var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
      var i = 0;
      input = this._utf8_encode(input);
      while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) {
          enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
          enc4 = 64;
        }
        output = output + this.keyStr.charAt(enc1) + this.keyStr.charAt(enc2) + this.keyStr.charAt(enc3) + this.keyStr.charAt(enc4);
      }
      return output;
    }
  
    decode(input: string) {
      var output = "";
      var chr1, chr2, chr3;
      var enc1, enc2, enc3, enc4;
      var i = 0;
      input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
      while (i < input.length) {
        enc1 = this.keyStr.indexOf(input.charAt(i++));
        enc2 = this.keyStr.indexOf(input.charAt(i++));
        enc3 = this.keyStr.indexOf(input.charAt(i++));
        enc4 = this.keyStr.indexOf(input.charAt(i++));
        chr1 = (enc1 << 2) | (enc2 >> 4);
        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
        chr3 = ((enc3 & 3) << 6) | enc4;
        output = output + String.fromCharCode(chr1);
        if (enc3 != 64) {
          output = output + String.fromCharCode(chr2);
        }
        if (enc4 != 64) {
          output = output + String.fromCharCode(chr3);
        }
      }
      output = this._utf8_decode(output);
      return output;
    }
  }

  const base64 = new Base64();

  export const toBase64 = function (u8: Uint8Array) {
    return base64.encode(String.fromCharCode.apply(null, u8 as any));
  }

  export const fromBase64 = function (str: string) {
    return new Uint8Array(base64.decode(str).split('').map(function (c) { return c.charCodeAt(0); }));
  }
  

  //----------------------------------------------------
// Using two-byte lookup table
// must call here before calling the above
//----------------------------------------------------
const LookupDigits = new Uint8Array([
  0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0, //gap: ctrl chars
  0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0, //gap: ctrl chars
  0,0,0,0,0,0,0,0,0,0,0,           //gap: spc,!"#$%'()*
  62,                   // +
   0, 0, 0,             // gap ,-.
  63,                   // /
  52, 53, 54, 55, 56, 57, 58, 59, 60, 61, // 0-9
   0, 0, 0,             // gap: :;<
  99,                   //  = (end padding)
   0, 0, 0,             // gap: >?@
   0, 1, 2, 3, 4, 5, 6, 7, 8, 9,10,11,12,13,14,15,16,
  17,18,19,20,21,22,23,24,25, // A-Z
   0, 0, 0, 0, 0, 0,    // gap: [\]^_`
  26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,
  43,44,45,46,47,48,49,50,51, // a-z    
   0, 0, 0, 0,          // gap: {|}~ (and the rest...)
  0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
  0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
  0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
  0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
]);

export function FromBase64Simple( pSrc: string )
{
   let nLenOut= 0
   let scrPtr = 0;
   const out = [];
   for( let j=0; j<pSrc.length; j+=4 ) {
      let s1= LookupDigits[ pSrc.charCodeAt(j) ] & 0xff;
      let s2= LookupDigits[ pSrc.charCodeAt(j+1) ] & 0xff;
      let s3= LookupDigits[ pSrc.charCodeAt(j+2) ] & 0xff;
      let s4= LookupDigits[ pSrc.charCodeAt(j+3) ] & 0xff;

      let d1= ((s1 & 0x3f) << 2) | ((s2 & 0x30) >> 4);
      let d2= ((s2 & 0x0f) << 4) | ((s3 & 0x3c) >> 2);
      let d3= ((s3 & 0x03) << 6) | ((s4 & 0x3f) >> 0);

      out.push(d1&0xff);  nLenOut++;
      if (s3==99) break;      // end padding found
      out.push(d2&0xff);  nLenOut++;
      if (s4==99) break;      // end padding found
      out.push(d3&0xff);  nLenOut++;
   }
   return new Uint8Array(out);
}


// const Base64Digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
const Base64Digits = new Uint8Array([0x41, 0x42, 0x43, 0x44, 0x45, 0x46, 0x47, 0x48, 0x49, 0x4a, 0x4b, 0x4c, 0x4d, 0x4e, 0x4f, 0x50,0x51, 0x52, 0x53, 0x54, 0x55, 0x56, 0x57, 0x58, 0x59, 0x5a, 0x61, 0x62, 0x63, 0x64, 0x65, 0x66,0x67, 0x68, 0x69, 0x6a, 0x6b, 0x6c, 0x6d, 0x6e, 0x6f, 0x70, 0x71, 0x72, 0x73, 0x74, 0x75, 0x76,0x77, 0x78, 0x79, 0x7a, 0x30, 0x31, 0x32, 0x33, 0x34, 0x35, 0x36, 0x37, 0x38, 0x39, 0x2b, 0x2f]);
export function ToBase64Simple( pSrc: Uint8Array)
{
  let nLenSrc = pSrc.length;
  let ptrSrc = 0;
   let nLenOut= 0;
   let out:number[] = [];
   while ( nLenSrc > 0 ) {// error

      // read three source bytes (24 bits) 
      let s1= pSrc[ptrSrc+0];   // (but avoid reading past the end)
      let s2= 0; if (nLenSrc>1) s2=pSrc[ptrSrc+1]; //------ corrected, thanks to  jprichey
      let s3= 0; if (nLenSrc>2) s3=pSrc[ptrSrc+2];

      let n  =  s1;    // xxx1
      n <<= 8;    // xx1x
      n |= s2;    // xx12  
      n <<= 8;    // x12x
      n |= s3;    // x123  
      n &= 0xffffff;

      //-------------- get four 6-bit values for lookups
      let m4= n & 0x3f;  n >>= 6;
      let m3= n & 0x3f;  n >>= 6;
      let m2= n & 0x3f;  n >>= 6;
      let m1= n & 0x3f;  
	
      //------------------ lookup the right digits for output
      let b1 = Base64Digits[m1];
      let b2 = Base64Digits[m2];
      let b3 = Base64Digits[m3];
      let b4 = Base64Digits[m4];

      //--------- end of input handling
      out.push(b1 & 0xff);
      out.push(b2 & 0xff);
      if ( nLenSrc >= 3 ) {  // 24 src bits left to encode, output xxxx
         out.push(b3);
         out.push(b4);
      }
      if ( nLenSrc == 2 ) {  // 16 src bits left to encode, output xxx=
         out.push(b3);
         out.push(0x3d);//'='.charCodeAt(0));
         }
      if ( nLenSrc == 1 ) {  // 8 src bits left to encode, output xx==
         out.push(0x3d);//'='.charCodeAt(0));
         out.push(0x3d);//'='.charCodeAt(0));
      }
      ptrSrc  += 3;
      nLenSrc -= 3;
      nLenOut += 4;
   }
   // Could optionally append a NULL byte like so:
   // *pDst++= 0; nLenOut++;
  //  return( nLenOut );  
  return String.fromCharCode.apply(null, out);
}
