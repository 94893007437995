export type BluetoothReceinveCallback = (data: Uint8Array | string) => void;

export class BluetoothGATT {
    private bluetooth = (navigator as any).bluetooth as Bluetooth;
    private characteristic: BluetoothRemoteGATTCharacteristic | null = null;
    private device:BluetoothDevice | null = null;
    private server: BluetoothRemoteGATTServer | null = null;
    private reconnectTimer: any = null;
    public isConnected = false;
    constructor(private receiveCallback: BluetoothReceinveCallback) {}

    setReceinveCallback(receiveCallback: BluetoothReceinveCallback) {
        this.receiveCallback = receiveCallback;
    }

    private onDataReceived(data: Uint8Array | string) {
      // console.log(data.length, " bytes received!");
      this.receiveCallback(data);
    }

    public async open() {
        // if (this.reconnectTimer) {
        //   console.log("clear interval timer!!!!!!!!!!")
        //   clearInterval(this.reconnectTimer);
        // }
        if (this.device) {
          this.characteristic = await this!.device.gatt!.connect()
          .then((server: BluetoothRemoteGATTServer) => {
            this.server = server;
            return this.server.getPrimaryService('0000ffe0-0000-1000-8000-00805f9b34fb');
          })
          .then((service: any) => service.getCharacteristic('0000ffe3-0000-1000-8000-00805f9b34fb'));
          
        } else {
            this.characteristic = await this.bluetooth.requestDevice({
              filters: [{
                services: ['0000ffe0-0000-1000-8000-00805f9b34fb']
              }]
            })
            .then((device: BluetoothDevice) => {
                this.device = device;
              this.device.addEventListener('gattserverdisconnected', this.onDisconnected);
              return this!.device.gatt!.connect()
            })
            .then((server: BluetoothRemoteGATTServer) => {
              this.server = server;
              return this.server.getPrimaryService('0000ffe0-0000-1000-8000-00805f9b34fb');
            })
            .then((service: any) => service.getCharacteristic('0000ffe3-0000-1000-8000-00805f9b34fb'));
        }
//          .then((characteristic: any) => characteristic.startNotifications());
         try {
         await this.characteristic?.startNotifications();
         this.characteristic?.addEventListener('characteristicvaluechanged',
                                 (event: any) => this.onDataReceived(new Uint8Array(event.target.value.buffer)));
         } catch(errr) {
           console.log("WTF??", errr);
         }
        
        console.log("connected");
        this.isConnected = true;
    }

    public write(data: Uint8Array) {
        if (!this.server?.connected) {
            this.isConnected = false;
            console.log("disconnected!");
            return;
        }
           this.characteristic?.writeValue(data).then().catch((error: any) => {
            this.isConnected = false;
            console.log("Bluetooth Device not response! Error: ", error);
            this.server?.disconnect();
            // ( async () => {
            //   const discRes = await this.server.disconnect().then(() => null).catch((error: any) => error)
            //   console.log("Bluetooth Server Disconnected!", discRes === null ? "" : `(With error: ${discRes})`);
            // })();
          });
    }

    public connected() {
      return this.isConnected;
    }

    private onDisconnected(event: any) {
        const device = event.target;
        // this.device = null;
        this.isConnected = false;
        // this.onDataReceived("device_disconnected");
        console.log(`Device ${device.name} is disconnected.`);
        // this.reconnectTimer = setInterval(() => {
        //   console.log("Attempt to reconnect");
        // }, 2000);
      }

    private connect() {
        this?.device?.gatt?.connect().then(() => console.log("connected!"))
      }

}
