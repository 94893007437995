import React, {useState} from 'react'
import {Button, Grid, Space, Avatar, TextArea, Switch } from 'antd-mobile'
// import { Slider } from 'antd';
// import logo from './../logo.svg';
import './Radio.css';
// import logo from "./walkie-talkie.png"
import logo from "./logo.svg"
import BluetoothTerminal from "bluetooth-terminal"
// import MicrophoneStream from "microphone-stream";
import AudioAnalyser from './AudioAnalyser';
// import * as waveResampler from "wave-resampler";
// import * as ffmpeg from "@ffmpeg/ffmpeg";

// import OpusToPCM from 'opus-to-pcm';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
// import { Typography } from '@mui/material';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import { useTheme } from '@mui/material/styles';
import {AACCodec, AACCodecModule, getWasm} from "aac-stream-wasm";
// import { Alloc } from 'aac-stream-wasm/dist/WasmModule';
import { fromBase64, FromBase64Simple, toBase64, ToBase64Simple } from '../utils/Base64';
import { BluetoothGATT } from '../utils/Bluetooth';
import { NoiseGateNode } from '../utils/worklets/NoiseGateNode';
// import { Alloc } from 'aac-stream-wasm/dist/WasmModule';

var aacModule: AACCodecModule;
var aacCodec: AACCodec; //AACCodecModule.AACCodec;
var bypasser: any;
// var alloc: Alloc;
getWasm().then((modul: AACCodecModule) => {
  aacModule = modul;
  aacCodec = new aacModule.AACCodec();
  // alloc = new aacModule.Alloc();

  /*!< Transport type to be used. See ::TRANSPORT_TYPE
                               in FDK_audio.h. Following types can be configured
                               in encoder library:
                                 - 0: raw access units
                                 - 1: ADIF bitstream format
                                 - 2: ADTS bitstream format
                                 - 6: Audio Mux Elements (LATM) with
                               muxConfigPresent = 1
                                 - 7: Audio Mux Elements (LATM) with
                               muxConfigPresent = 0, out of band StreamMuxConfig
                                 - 10: Audio Sync Stream (LOAS) */


  /** 
	 * @brief  Audio object type. See ::AUDIO_OBJECT_TYPE in FDK_audio.h.
                   - 2: MPEG-4 AAC Low Complexity.
                   - 5: MPEG-4 AAC Low Complexity with Spectral Band Replication
                 (HE-AAC).
                   - 29: MPEG-4 AAC Low Complexity with Spectral Band
                 Replication and Parametric Stereo (HE-AAC v2). This
                 configuration can be used only with stereo input audio data.
                   - 23: MPEG-4 AAC Low-Delay.
                   - 39: MPEG-4 AAC Enhanced Low-Delay. Since there is no
                 ::AUDIO_OBJECT_TYPE for ELD in combination with SBR defined,
                 enable SBR explicitely by ::AACENC_SBR_MODE parameter. The ELD
                 v2 212 configuration can be configured by ::AACENC_CHANNELMODE
                 parameter.
                   - 129: MPEG-2 AAC Low Complexity.
                   - 132: MPEG-2 AAC Low Complexity with Spectral Band
                 Replication (HE-AAC).

                   Please note that the  MPEG-2 AOT's basically disables
                 non-existing Perceptual Noise Substitution tool in AAC encoder
                 and controls the MPEG_ID flag in adts header. The 
                 MPEG-2 AOT doesn't prohibit specific transport formats. 
			*/

  // const result = aacCodec.aacEncoderInit(2, 1, 24000, 32000, 0);
  const result = aacCodec.aacEncoderInit(2, 1, 24000, 28000, 0, 2);
  // const result = aacCodec.aacEncoderInit(2, 1, 22050, 64000);
  console.log("Encoder initialized with code:", result);

  const decoderResult = aacCodec.aacDecoderInit(512);
  console.log("Decoder initialized with code: ", decoderResult);
});


function valuetext(value: number) {
  return `${value}°C`;
}
var frameCntr = 0;
const marks = [
  {
    value: 20,
    label: '20',
  },
  {
    value: 15,
    label: '15',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 0,
    label: '0',
  },
  {
    value: -3,
    label: '-3',
  },
  {
    value: -10,
    label: '-10',
  },
  {
    value: -15,
    label: '-15',
  },
  {
    value: -20,
    label: '-20',
  },
];

// getWasm().then((module) => {
//   console.log('The result is: ', module.add(3, 4));
// });

// Module.print = console.log;
// const wasmReady = new Promise(resolve => {
//   Module['onRuntimeInitialized'] = () => {
//     console.log("wasm ready!");
//     resolve();
//   };
// });


let terminal = new BluetoothTerminal();

let bluetooth: BluetoothGATT;

var outBuffer = new Uint8Array([]);

var timeOp = 0;


var biquadFilter1: BiquadFilterNode;//= audioContext.createBiquadFilter();
var biquadFilter2: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter3: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter4: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter5: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter6: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter7: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter8: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter9: BiquadFilterNode;// = audioContext.createBiquadFilter();
var biquadFilter10: BiquadFilterNode;// = audioContext.createBiquadFilter();

var audioContext: AudioContext;

var downsampleBuffer = function (buffer: ArrayLike<number>, sampleRate: number, outSampleRate: number) {
  if (outSampleRate == sampleRate) {
      return buffer;
  }
  if (outSampleRate > sampleRate) {
      throw "downsampling rate show be smaller than original sample rate";
  }
  var sampleRateRatio = sampleRate / outSampleRate;
  var newLength = Math.round(buffer.length / sampleRateRatio);
  var result = new Int16Array(newLength);
  var offsetResult = 0;
  var offsetBuffer = 0;
  while (offsetResult < result.length) {
      var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      var accum = 0, count = 0;
      for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
          accum += buffer[i];
          count++;
      }

      result[offsetResult] = Math.min(1, accum / count)*0x7FFF;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
  }
  return result.buffer;
}

// let outData = [];
var cnttt = 0;
function Radio() {
    const [logText, setLogText] = useState<string>("");
    const [audioBypass, setAudioBypass] = useState<boolean>(false);
    const [connectText, setConnectText] = useState<string>("Connect");
    const [micText, setMicText] = useState<string>("Send audio");
    const [audio, setAudio] = useState<MediaStream>();
    const [biquadFilter30, setBiquadFilter30] = useState<number>(0);
    const [biquadFilter60, setBiquadFilter60] = useState<number>(0);
    const [biquadFilter120, setBiquadFilter120] = useState<number>(0);
    const [biquadFilter250, setBiquadFilter250] = useState<number>(0);
    const [biquadFilter500, setBiquadFilter500] = useState<number>(0);
    const [biquadFilter1000, setBiquadFilter1000] = useState<number>(0);
    const [biquadFilter2000, setBiquadFilter2000] = useState<number>(0);
    const [biquadFilter4000, setBiquadFilter4000] = useState<number>(0);
    const [biquadFilter8000, setBiquadFilter8000] = useState<number>(0);
    const [biquadFilter16000, setBiquadFilter16000] = useState<number>(0);

    const theme = useTheme();

    // var wsClient = new WebSocket("wss://marcin.bialystok.pl:1221");//process.env.REACT_APP_WEBSOCKET_SERVER ?? "ws://localhost:1221");

    // wsClient.onopen = (_event) => {
    //   wsClient.send("Hello I'm WebPMR");
    // };

    // wsClient.onmessage = (event) => {
    //     //tu leca ramki
    //   console.log("Server data: ", JSON.stringify(event));
    // };

    const onBluetoothData = (dataBt: Uint8Array | string) => {
      if (typeof dataBt === "string") {
        bypasser?.port.postMessage({
          command: "device_disconnected",
        });

        return;
      }
      const dataB64 = ToBase64Simple(dataBt as Uint8Array);
      const decoderRes = aacCodec.aacDecodeB64(dataB64);
      if (decoderRes.length % 4 !== 0) {
        console.log("probably wrong packet", decoderRes);
      } else {
        bypasser?.port.postMessage({
          command: "dataBt",
          data: decoderRes,
        });

        // const decodedPCM = FromBase64Simple(decoderRes);
        // console.log(decodedPCM);
      }
      // bypasser.port.
    }

    const connect = async () => {
        if (!bluetooth) {
          console.log("nie ma obiektu bluetooth")
          bluetooth = new BluetoothGATT(onBluetoothData);
        }
        if (!bluetooth.isConnected) {
          console.log("obiekt istnieje, connect!")
          bluetooth.open();
        }
        
        // terminal.receive = function(data: any) {
        //     setLogText(data);
        // };
        // setConnectText("Connecting...")
        // try {
        //     terminal.connect().then(() => {
        //         setConnectText("Disconnect");
        //     });
        // } catch (e) {
        //     setLogText(() => ((e as any).message));
        //     setConnectText("Connect");
        // }
    }

    const disconnect = async () => {
        setConnectText("Connect");
        try {
            terminal.disconnect();
        } catch (e) {

        }
        try {
            terminal = new BluetoothTerminal();
        } catch (e) {

        }
        // terminal.send('Simon says: Hello, world!');
    }


    const sendAdudio = async () => {
        
    };


    

    const appendBuffer = function(buffer1: Uint8Array, buffer2: ArrayBuffer) {
      //const input = buffer2 as Uint16Array;
      //const resampl: ArrayLike<number> = waveResampler.resample(buffer1, 48000, 16000) as ArrayLike<number>;
      // const resampl = new Uint8Array(downsampleBuffer(buffer1, 16000, 16000));
      // const resampl = buffer1;
      const resampled = buffer1;//Uint8Array.from(resampl);
      var tmp = new Uint8Array(resampled.byteLength + buffer2.byteLength);
      tmp.set(new Uint8Array(resampled), 0);
      tmp.set(new Uint8Array(buffer2), resampled.byteLength);
      return tmp;
    };

    let bypasserWorklet: any;

    const bypassAudio = (bypass: boolean) => {
      console.log("a to działa....")
      // if (bypasser) {
        console.log("kurwa set!!! ", bypass)
        bypasser.port.postMessage({
          command: "mute",
          data: {
            mute: !bypass,
          }
        });
      // }
    }


    const getMicrophone = async () => {
      setMicText(() => "Stop");
      outBuffer = new Uint8Array([]);
        const audio = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        });

        
        audioContext = new AudioContext({
          latencyHint: "balanced",
          sampleRate: 48000,
        });
        console.log("base latency:", audioContext.baseLatency);
        // audioContext = new (window.AudioContext ||
        //   (window as any).webkitAudioContext)();
        // const audioContext = new (window.AudioContext)();
        const analyzer = audioContext.createAnalyser();
        // const dataArr = new Uint8Array(analyzer.frequencyBinCount);
        const dataArr = new Float32Array(analyzer.frequencyBinCount);
        var source = audioContext.createMediaStreamSource(audio);

        

// var somm = audioContext.createMediaStreamDestination();
// var somm2 = audioContext.createMediaStreamDestination();
// audioContext.sampleRate
await audioContext.audioWorklet.addModule('/worklets/processor.js')
// await audioContext.audioWorklet
console.log("loading module: ", await import.meta.url);
// await audioContext.audioWorklet.addModule(new URL('../utils/audio', import.meta.url));
// console.log("Audio module loaded!");
// await audioContext.audioWorklet.addModule(new URL('../utils/processor.js', import.meta.url), {credentials: "omit"});
console.log("process loaded!");
// .then(() => {
//   let node = new AudioWorkletNode(audioContext, 'port-processor');
//   node.port.onmessage = (event) => {
//     // Handling data from the processor.
//     console.log(event.data);
//   };

//   node.port.postMessage('Hello!');
// }).catch((err) => console.log("jaki grzyb???? ", err.message));

bypasser = new AudioWorkletNode(audioContext, 'port-processor');
bypasser.port.postMessage({
  command: "init",
  data: {
    sampleRate: audioContext.sampleRate,
    mute: false,//!audioBypass,
  }
});

bypasserWorklet = bypasser;

let timestampLast = Date.now();
let dataSizeCounter = 0;
let dataPacketMin = Number.MAX_SAFE_INTEGER;
let dataPacketMax = 0;
let dataPacketCounter = 0;

// console.log("in ms? ", timestampLast)

bypasser.port.onmessage = (event: any) => {

  // for (let i = 0; i < 100000; i++) {
  //   for (let j = 0; j < 10000; j++) {

  //   }
  // }
  if (event.data.length > 0 && event.data !== "error") {
  const res = aacCodec.aacEncodeB64(event.data);

  if (res === "error") {
    console.log("Error during pcm encode!");
    return;
  }
  if (res.length > 0) {
    frameCntr++;
    // outBuffer = appendBuffer(outBuffer, FromBase64Simple(res));
    // console.log(Array.from(FromBase64Simple(res)).map((byte => byte.toString(16))));

    const dataAudioToBt = FromBase64Simple(res);
    dataSizeCounter += dataAudioToBt.length;
    dataPacketCounter++;
    dataPacketMax = Math.max(dataPacketMax, dataAudioToBt.length);
    dataPacketMin = Math.min(dataPacketMin, dataAudioToBt.length);

    const timeNow = Date.now()
    if (timeNow>= (timestampLast + 1000)) {
      timestampLast = timeNow;
      const datSiz = dataSizeCounter;
      const _dataPacketCounter = dataPacketCounter;
      const _dataPacketMax = dataPacketMax;
      const _dataPacketMin = dataPacketMin;
      dataPacketCounter = 0;
      dataSizeCounter = 0;
      dataPacketMin = Number.MAX_SAFE_INTEGER;
      dataPacketMax = 0;
      console.log(`Total packets per second: ${_dataPacketCounter} (1 per ${Math.round((1000 / _dataPacketCounter) * 10) / 10} ms)`);
      console.log(`Bitrate avg: ${Math.round((datSiz * 8 / 1000)*10)/10} kbit/s (Min: ${Math.round((_dataPacketMin * _dataPacketCounter * 8 /1000) * 10)/10} kbits/s Max: ${Math.round((_dataPacketMax * _dataPacketCounter * 8 / 1000)*10)/10} kbits/s)`);
      
    }
    if (bluetooth?.connected()) {


    // bluetooth.write(dataAudioToBt);
      
      
      
      // console.log("Sending audio to bluetooth device: ", dataAudioToBt.length);
      
      // terminal.send(String.fromCharCode.apply(null, Array.from(FromBase64Simple(res))));
    }
    // const oldTimeOp = timeOp;
    // timeOp = Date.now();
    // console.log(`Bytes: ${dataAudioToBt.length} per ${timeOp - oldTimeOp}ms`);
  }
}
    // frameCntr++;
    // if (frameCntr % 510 === 0) {
    // const enc64 = aacCodec.base64test(event.data);
    // console.log(event.data === enc64, enc64);
    // console.log("Frame: ", ++frameCntr, event.data);
  //    frameCntr = 0;
  // }
};
var mute = false;
// let cnttt = 0;
// const cnt = () => {
// // cnttt++;
// console.log(cnttt);
// }
// setInterval(() => {
//   cnt();
//   if (cnttt = 1) {
//     bypasser.port.postMessage({
//       command: "mute",
//       data: {
//         mute: false,
//       }
//     })
//   }
  
//   if (cnttt === 4) {
//     mute = true;
//     cnttt = 0;
//     bypasser.port.postMessage({
//       command: "mute",
//       data: {
//         mute: true,
//       }
//     })
//   }
//   console.log("ddddddd", cnttt++, cnt())

// }, 1000);
// var somm = audioContext.createScriptProcessor();
var somm = audioContext.createMediaStreamDestination();
// somm.addEventListener('audioprocess', (e) => {
//   console.log("cos jest",e);
//   // const rawLeftChannelData = somm.getChannelData(0);
//   // rawLeftChannelData is now a typed array with floating point samples
// });

  biquadFilter1= audioContext.createBiquadFilter();
  biquadFilter2 = audioContext.createBiquadFilter();
  biquadFilter3 = audioContext.createBiquadFilter();
  biquadFilter4 = audioContext.createBiquadFilter();
  biquadFilter5 = audioContext.createBiquadFilter();
  biquadFilter6 = audioContext.createBiquadFilter();
  biquadFilter7 = audioContext.createBiquadFilter();
  biquadFilter8 = audioContext.createBiquadFilter();
  biquadFilter9 = audioContext.createBiquadFilter();
  biquadFilter10 = audioContext.createBiquadFilter();


  const filerType: BiquadFilterType = "peaking";
  biquadFilter1.type = "lowpass";//filerType;
  biquadFilter1.frequency.value = 1000;
  console.log("low pass 1 Q: min,max: ", biquadFilter1.Q.minValue, biquadFilter1.Q.maxValue);
  // biquadFilter1.gain.value = biquadFilter30;
  // biquadFilter1.Q.value = 0.5773;
  // biquadFilter1.connect(bypasser);

  // source.connect(biquadFilter5);
  // source.connect(biquadFilter1);
  const noiseGateNode = new NoiseGateNode(audioContext).getNoiseGate();

//   var compressor = audioContext.createDynamicsCompressor();
// compressor.threshold.setValueAtTime(-10, audioContext.currentTime);
// compressor.knee.setValueAtTime(40, audioContext.currentTime);
// compressor.ratio.setValueAtTime(12, audioContext.currentTime);
// compressor.attack.setValueAtTime(0, audioContext.currentTime);
// compressor.release.setValueAtTime(0.25, audioContext.currentTime);
// compressor.connect(bypasser);

var highshelfFilter1 = audioContext.createBiquadFilter();
var highshelfFilter2 = audioContext.createBiquadFilter();
var lowshelfFilter3 = audioContext.createBiquadFilter();
var lowshelfFilter4 = audioContext.createBiquadFilter();
var highshelfFilter4 = audioContext.createBiquadFilter();

highshelfFilter1.type="highshelf";
highshelfFilter2.type="highshelf";
lowshelfFilter3.type="highshelf";


highshelfFilter1.connect(highshelfFilter2);


// source.connect(bypasser);
const fftAnalyser = audioContext.createAnalyser();
fftAnalyser.fftSize = 1024;

var paths = document.getElementsByTagName('path');
var visualizer = document.getElementById('visualizer');
var mask = (visualizer as any).getElementById('mask');
// var h = document.getElementsByTagName('h1')[0];
var path;
var report = 0;

var frequencyArray = new Uint8Array(fftAnalyser.frequencyBinCount);
        (visualizer as any).setAttribute('viewBox', '0 0 255 255');
      
				//Through the frequencyArray has a length longer than 255, there seems to be no
        //significant data after this point. Not worth visualizing.
        for (var i = 0 ; i < 255; i++) {
            path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            path.setAttribute('stroke-dasharray', '4,1');
            mask.appendChild(path);
        }
        var doDraw = function () {
            requestAnimationFrame(doDraw);
            fftAnalyser.getByteFrequencyData(frequencyArray);
          	var adjustedLength;
            for (var i = 0 ; i < 255; i++) {
              	adjustedLength = Math.floor(frequencyArray[i]) - (Math.floor(frequencyArray[i]) % 5);
                paths[i].setAttribute('d', 'M '+ (i) +',255 l 0,-' + adjustedLength);
            }

        }
        doDraw();






source.connect(bypasser);
// source.connect(lowshelfFilter3);


// bypasser.connect(lowshelfFilter3);
// lowshelfFilter3.connect(bypasser);
lowshelfFilter3.frequency.setValueAtTime(10000, audioContext.currentTime+1);
lowshelfFilter3.gain.setValueAtTime(-50, audioContext.currentTime+10);

// lowshelfFilter3.connect(highshelfFilter1);
// lowshelfFilter3.frequency.setValueAtTime(12000, audioContext.currentTime+10);
// lowshelfFilter3.gain.setValueAtTime(-10, audioContext.currentTime+10);

// highshelfFilter1.connect(bypasser)
// highshelfFilter1.frequency.setValueAtTime(10000, audioContext.currentTime+10);
// highshelfFilter1.gain.setValueAtTime(-20, audioContext.currentTime+10);


// highshelfFilter2.connect(bypasser);
// highshelfFilter2.frequency.setValueAtTime(4000, audioContext.currentTime+1);
// highshelfFilter2.gain.setValueAtTime(-40, audioContext.currentTime+1);


bypasser.connect(audioContext.destination);
bypasser.connect(fftAnalyser);
//lowshelfFilter3.connect(fftAnalyser);
// lowshelfFilter3.connect(bypasser);
// bypasser.connect(audioContext.destination);
// bypasser.connect(fftAnalyser);
// highshelfFilter3.connect(bypasser);
// highshelfFilter3.frequency.setValueAtTime(11000, audioContext.currentTime);
// highshelfFilter3.gain.setValueAtTime(-20, audioContext.currentTime);
  // source.connect(bypasser);
  // noiseGateNode.connect(bypasser);


  biquadFilter2.type = "lowpass";//filerType;
  biquadFilter2.frequency.value = 7000;
  // biquadFilter2.gain.value = biquadFilter60;
  biquadFilter2.Q.value = 0.1;
  // biquadFilter2.connect(biquadFilter3);

  biquadFilter3.type ="lowpass";//filerType;
  biquadFilter3.frequency.value = 7000;
  // biquadFilter3.gain.value = biquadFilter120;
  biquadFilter3.Q.value = 0.1;
  // biquadFilter3.connect(biquadFilter4);

  biquadFilter4.type = "lowpass";//filerTypeType;
  biquadFilter4.frequency.value = 7000;
  // biquadFilter4.gain.value = biquadFilter250;
  biquadFilter4.Q.value = 0.1;
  // biquadFilter4.connect(bypasser);

  biquadFilter5.type = filerType;
  biquadFilter5.frequency.value = 500;
  biquadFilter5.gain.value = biquadFilter500;
  biquadFilter5.Q.value = 0.1;
  // biquadFilter6.Q.value = 0.25;
  // biquadFilter5.connect(biquadFilter6);

  biquadFilter6.type = filerType;
  biquadFilter6.frequency.value = 1000;
  biquadFilter6.gain.value = biquadFilter1000;
  biquadFilter6.Q.value = 0.1;
  // biquadFilter6.Q.value = 0.5;
  // biquadFilter6.connect(biquadFilter7);

  biquadFilter7.type = filerType;
  biquadFilter7.frequency.value = 2000;
  console.log(biquadFilter7.gain.minValue, biquadFilter7.gain.maxValue);
  biquadFilter7.gain.value = biquadFilter2000;
  biquadFilter7.Q.value = 0.1;
  // biquadFilter7.connect(biquadFilter8);

  biquadFilter8.type = filerType;
  biquadFilter8.frequency.value = 4000;
  biquadFilter8.gain.value = biquadFilter4000;
  biquadFilter8.Q.value = 0.1;
  // biquadFilter8.connect(biquadFilter9);

  biquadFilter9.type =filerType;
  biquadFilter9.frequency.value = 8000;
  biquadFilter9.gain.value = biquadFilter8000;
  biquadFilter9.Q.value = 0.1;
  // biquadFilter9.connect(biquadFilter10);

  // biquadFilter10.type = filerType;
  // biquadFilter10.frequency.value = 16000;
  // biquadFilter10.gain.value = biquadFilter16000;



  // biquadFilter.connect(somm);
  //tu podpinamy do wyjscia
  // biquadFilter9.connect(audioContext.destination)
  // biquadFilter9.connect(bypasser);


  // bypasser.connect(compressor);
  // compressor.connect(audioContext.destination);
  
  // noiseGateNode.connect(audioContext.destination);
// source.connect(audioContext.destination);

        

      //   const decoder = new OpusToPCM({
      //     channels: 1
      // });

    //   decoder.on('decode', (pcmData: any)=> {
    //     console.log(pcmData); /* PCM data */
    // }); 
        // var options: MediaRecorderOptions = {
        //     audioBitsPerSecond : 48000,
        //     bitsPerSecond: 48000,
        //     // videoBitsPerSecond : 2500000,
        //     mimeType : 'audio/webm;codecs="pcm"'
        //   }
          // analyzer
          //if(!MediaRecorder.isTypeSupported(options['mimeType'])) 
          // options['mimeType'] =  "audio/webm\;codecs:aac";
          // const voice = new MediaRecorder(audio, options);
          // voice.start(20);
          // voice.ondataavailable = async function(data){

          //   analyzer.getFloatTimeDomainData(dataArr);
          //   var output = new Uint8Array(dataArr.buffer);

          //  // outBuffer = appendBuffer(outBuffer, await data.data.arrayBuffer());
          //   biquadFilter5.gain.value = biquadFilter500;
          //   biquadFilter6.gain.value = biquadFilter1000;
          //   biquadFilter7.gain.value = biquadFilter2000;
          // };
      
          // voice.onstop = function(){
          //   console.log('stop audio call');
          // }
        // const source = audioContext.createMediaStreamSource(audio);
        // var options = {mimeType: 'audio/webm;codecs=pcm'};
// const mediaRecorder = new MediaRecorder(audio, options);
// (mediaRecorder as any).ondataavailable((ev: any) => {

// })
        //source.context.decodeAudioData()
        setAudio( () => audio );
}
    
    const  stopMicrophone= () => {
      setMicText(() => "Send audio");
        console.log(Array.from(outBuffer));
        // audio!.getTracks().forEach(track => track.stop());
        // setAudio( () => null as any );

        audioContext.close();
        frameCntr = 0;
      }
    
    const  toggleMicrophone= () => {
        if (audio) {
          stopMicrophone();
        } else {
          getMicrophone();
        }
      }

      const lightIconColor =
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';

    // const module = new Greeter("elo");

// withGreeterScope()
    // withGreeter(greeterModule => {
    //   // construct a new C++ `Greeter` instance
    //   const greeter = new greeterModule.Greeter("Wasm");
    
    //   // call a member function
    //   console.log(greeter.greet(greeterModule.LanguageCode.EN));
      
    //   // any created C++ objects will be destroyed after the function exits, unless they are persisted
    // });

    // const greeter = new greeterModule.Greeter("Wasm");
    const test = async() => {
      // greeterModule.greet
      // const encodedB64 = "SGVqIHRvIGplc3QgdHJvY2jEmSB0ZWtzdHUgemFrb2Rvd2FuZWdvIHcgYmFzZSA2NCBiZXogYmluYXJueWNoIHpuYWvDs3ch";
      // const b64t = aacCodec.base64test(encodedB64)
      // console.log("tu", b64t, b64t.length, b64t === encodedB64);
      const array = new Uint8Array(5);
      array.set([1, 2, 3, 4 ,5]);
      // console.log(alloc.reverseBinary("12345"));
      // console.log(aacCodec.greet(aacModule.LanguageCode.EN));
      // console.log(aacCodec.aacenc_init(2, 1, 24000, 56000));
      // var input_ptr  = (aacModule as any)._malloc(1 );
      // const array = new Int32Array(5);
      // var memory = new WebAssembly.Memory({initial:10, maximum:100, shared: true});

      // let myArrayPtr = accMod.allocateF32Array(5);
      // let myArray = new Int32Array(memory.buffer);
      // let myArray = (aacModule as any).getArray(Float32Array, (aacModule as any).allocate(5 * 4));
      // let myArray = new Int8Array(input_ptr, 5*4);
      // const memory = new WebAssembly.Memory({ initial: 2 });
      // var input_ptr = alloc.getMem() as ArrayBuffer;
      // console.log("ptr: ", memory.buffer)
      // let myArray = new Int8Array(memory.buffer, 5);
      let myArray = new Uint8Array(8);

      myArray.set([1, 2, 3, 5, 7, 253, 254, 255]);
      const rr = ToBase64Simple(myArray);
      console.log("start", rr, FromBase64Simple(rr));
      const deco = aacCodec.base64test(ToBase64Simple(myArray));
      console.log("raw: ", deco);
      console.log("base64 test: ", FromBase64Simple(deco));
      // array.set([1, 2, 3, 5, 7]);
      console.log(myArray,  String.fromCharCode(...Array.from(myArray)));

      // const reversed = alloc.reverse(String.fromCharCode(...Array.from(myArray)));
      
      // console.log(reversed.split('').map(Number));
      // console.log(new TextEncoder().encode(reversed));
      // console.log(alloc.reverse("dupa"));
      // console.log(alloc.doIt(myArray));

      const arr = [1,2,3,4,5];
      try {
        const resl = aacCodec.sumArrayInt32(myArray, 5);
      // aacCodec.instance.export
        console.log(resl);
      } catch(e) {
        console.log("error!!!!! wasm ", e);
      }
      // console.log(await addNumbers(1, 10));
    }

    const styles = {
      container: {
        width: 300,
        height: 300,
        margin: '50px auto',
        backgroundColor: "orange",
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: "bold",
      },
    } as const;
    return (
        <div className="demoBlock">
            <Grid columns={2}>
                <Grid.Item>
                    <Space block wrap>
                        <Avatar src={logo} />
                    <div className='title grid-demo-item-block'>WebPMR</div>
                    </Space>
                </Grid.Item>
                <Grid.Item>

                    <div className='title grid-demo-item-block right'>2022 SP4MK</div>
                </Grid.Item>
            </Grid>
            <div className="main">
                <Space>
                    <Button color='primary' onClick={() => connectText === "Connect" ? connect() : disconnect()}>{connectText}</Button>
                    <Button color='danger' onClick={() => micText === "Send audio" ? getMicrophone() : stopMicrophone()}>{micText}</Button>
                    <Button color='success' onClick={() => test()}><div id="file">Init audio codec</div></Button>
                    <Switch checked={audioBypass}
                      onChange={checked => {
                        setAudioBypass(() => checked);
                        bypassAudio(checked);
                      }}
                    />
                </Space>
                
            </div>
            <div className="visual-canvas">
            {/* {audio ? <AudioAnalyser className="visual-canvas" audio={audio} /> : ''} */}
            </div>
            <div className="visual-canvas"></div>
            <div>
              <svg preserveAspectRatio="none" id="visualizer" version="1.1" xmlns="http://www.w3.org/2000/svg" >
              <defs>
                  <mask id="mask">
                      <g id="maskGroup">
                    </g>
                  </mask>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                      <stop offset="0%" style={{"stopColor": "#ff0a0a", "stopOpacity":1}} />
                      <stop offset="20%" style={{"stopColor": "#f1ff0a", "stopOpacity":1}} />
                      <stop offset="90%" style={{"stopColor": "#d923b9", "stopOpacity":1}} />
                      <stop offset="100%" style={{"stopColor": "#050d61", "stopOpacity":1}} />
                  </linearGradient>
              </defs>
              <rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" mask="url(#mask)"></rect>
              </svg>
            </div>
            {/* <Slider></Slider> */}
            <Stack sx={{ height: 300 }} spacing={4} direction="row">
      </Stack>
      <Stack spacing={2} direction="row" sx={{ mb: 1, px: 1 }} alignItems="center">
          <VolumeDownRounded htmlColor={lightIconColor} />
          <Slider
            valueLabelDisplay="on"
            aria-label="Volume"
            defaultValue={7000}
            min={500}
            max={10000}
            onChange={(_, value) => {
              biquadFilter1.frequency.setValueAtTime(value as number, audioContext.currentTime+128);
              console.log(value);
            }}
            sx={{
              color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
              '& .MuiSlider-track': {
                border: 'none',
              },
              '& .MuiSlider-thumb': {
                width: 24,
                height: 24,
                backgroundColor: '#fff',
                '&:before': {
                  boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                },
                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                  boxShadow: 'none',
                },
              },
            }}
          />
          <VolumeUpRounded htmlColor={lightIconColor} />
        </Stack>

            <Space wrap={false} block={false} justify="stretch">
                <TextArea
                    placeholder=''
                    value={logText}
                    // onChange={val => {
                    //     setValue(val)
                    // }}
                />
                
            </Space>
            
        </div>
    );
}

export default Radio;
